<template>
  <div class="dashboard__col change_pass">
    <!-- <h1>{{$t('changePass.mainTitle')}}</h1> -->
    <div class="orders__col">
      <div class="container">
        <div class="old_pass">
          <span class="old_pass_message" :style="{'visibility': showMessage ? 'visible' : 'hidden'}">{{errMess2}}</span>
          <input type="password" :placeholder="$t('changePass.oldPass')" v-model="oldPass">
        </div>
        <span class="new_pass_message" :style="{'visibility': !checkPass ? 'visible' : 'hidden'}">{{$t('changePass.errMes1')}}</span>
        <div class="new_pass">
          <div class="left">
            <input type="password" :placeholder="$t('changePass.newPass')" v-model="newPass1">
          </div>
          <div class="right">
            <input type="password" :placeholder="$t('changePass.repeatPass')" v-model="newPass2">
          </div>
        </div>
        <button class="btn_save" :disabled="!checkPass && oldPass.lenght >= 3" @click="sendPass()" ref="btn_send">{{$t('changePass.save')}}</button>
        <!-- <button class="btn_save" @click="test()">Сохранить</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  data(){
    return {
      oldPass: '',
      newPass1: '',
      newPass2: '',
      errMess2: "",
      showMessage: false
    }
  },
  computed: {
    ...mapGetters({
      lang : 'languages/getLang',
      user: 'user/user'
    }),
    checkPass(){
      return this.newPass1 == this.newPass2;
    }
  },
  methods: {
    async sendPass(){
      if(this.checkPass){
        try {
          let request = await fetch(`${domain}/${this.lang}/auth/users/set_password/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Token " + this.user.token
            },
            body: JSON.stringify({
              "new_password": this.newPass1,
              "current_password": this.oldPass
            })
          });

          if(request.ok){
            this.showMessage = false;
            this.$refs.btn_send.style.backgroundColor = "#008000bf";
            this.oldPass = ""
            this.newPass1 = "";
            this.newPass2 = "";
          }
          else {
            let res = await request.json();
            let keys = Object.keys(res);
            this.errMess2 = res[keys[0]][0];
            this.showMessage = true;
            console.warn(res);
          }
        }
        catch(ex){
          console.error(ex);
        }
      }

    },

  }
}
</script>

<style lang="scss">
.change_pass {
  margin-top: 2em;
  .container {
    max-width: 500px;
    margin: auto;
    .old_pass {
      .old_pass_message {
        font-size: 0.8rem;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 5px;
        color: rgba(255, 0, 0, 0.521);
      }
      margin-top: 50px;
    }
    .new_pass_message {
      display: inline-block;
      font-size: 0.8rem;
      font-weight: bold;
      margin-top: 25px;
      margin-bottom: 5px;
      color: rgba(255, 0, 0, 0.521);
    }
    .new_pass {
      display: flex;
      .left {
        margin-right: 20px;
      }
      @media(max-width: 768px) {
        flex-direction: column;
        .left {
          margin-right: 0;
          margin-bottom: 25px;
        }
      }
    }
    input {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: none;
      padding-left: 10px;
      outline: none;
      background-color: #efeefe;
    }
    .btn_save {
      width: 100%;
      margin-top: 35px;
      height: 50px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      background-color: #930f81c7;
      color: white;
      font-weight: 500;
      transition: 0.5s;
      &:hover {
        background-color: #930f81;
      }
    }
  }
}
</style>